import '../Hotels/HotelTab.scss';
import './CaravaningTab.scss';

import { MediaMatcher, MediaServerRender } from 'react-media-match';

import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { Fenrir } from '../../Fenrir/Fenrir';
import H2 from '../../Titles/TitleH2/TitleH2';
import H3 from '../../Titles/TitleH3/TitleH3';
import Hellheim from '../../Hellheim/Hellheim';
import { Freya } from '../../Freya/Freya';
import { Odin } from '../../Odin/Odin';
import Thor from '../../Thor/Thor';
import Tyr from '../../Tyr/Tyr';
// import Loki from '../../Loki/lokipane';
import Ymir from '../../Ymir/Ymir';
import tt from '../../../helpers/translation';

function TabContainer(props) {
  const { children } = props; //eslint-disable-line
  return (
    <Typography
      component="div"
      style={{ fontFamily: 'Lato', width: '100%' }}
    >
      {children}
    </Typography>
  );
}


TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = () => ({
  root: {
    flexGrow: 1,
    backgroundColor: 'transparent',
    border: 'none',
    fontFamily: 'Lato',
  },

  default_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 0.8,
    height: '50px',
    width: 'auto',
    padding: '5px',
    marginRight: '3px',
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: '22px',
    textAlign: 'center',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },

  active_tabStyle: {
    backgroundColor: '#FFFFFF',
    opacity: 1,
    height: '60px',
    width: 'auto',
    padding: '5px',
    borderRadius: '0 0 10px 10px',
    marginRight: '3px',
    fontWeight: '900',
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: '#1396D1',
    boxShadow: '0 2px 4px 0 rgb(0,0,0.5);',
    fontFamily: 'Lato',
  },
  indicator: {
    backgroundColor: 'transparent',
  },
  Wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
    width: '100%',
    fontFamily: 'Lato',
  },
  labelContainer: {
    marginTop: '0px',
    fontFamily: 'Lato',
    padding: '0px',
  },
  active_labelContainer: {
    marginTop: '0px',
    fontFamily: 'Lato',
  },
});

class HotelsTab extends React.Component {
  state = {
    value: null,
    openmenu1: false,
    activeTab: null,
  };


  handleClick = key => (e) => {
    const obj = {};
    this.state[key] = !this.state[key];
    this.setState(obj);
  };


  handleChangeTab = (event, value) => {
    this.setState({ value });
    this.props.onChangeData(value);
  };


  handleChange = (value) => {
    this.setState({ value });
    this.props.onChangeData(value);
  };

  render() {
    const {
      state: {
        openmenu1,
      },
      props: {
        data,
        classes,
      },
    } = this;

    const { value } = this.state;

    if (!value && data) {
      this.setState({ value: data.type });
      switch (data.type) {
        case 'INFO':
          this.setState({ activeTab: tt('INFORMACIÓN GENERAL', this.props.locale) });
          break;

        case 'SERVICES':
          this.setState({ activeTab: tt('SERVICIOS', this.props.locale) });
          break;

        case 'ARRIVE':
          this.setState({ activeTab: tt('CÓMO LLEGAR', this.props.locale) });
          break;

        default:
          this.setState({ activeTab: tt('INFO', this.props.locale) });
          break;
      }
    }



    const isMobileTrue = (
      <div className={`tab-menu ${data.type === 'INFO' ? 'bggrey' : ''}`}>
        <ListItem button className="firstlevelpasesmenu" onClick={this.handleClick('openmenu1')}>
          <ListItemText className="firstlevelpasesmenu-text" inset primary={`${this.state.activeTab}`} />
          {openmenu1 ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openmenu1} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem button className="secondpaseslevelmenu" value="INFO" onClick={() => this.handleChange('INFO')}>
              <ListItemText className="secondpaseslevelmenu-text" inset primary={tt('INFORMACIÓN GENERAL', this.props.locale)} />
            </ListItem>

            <ListItem button className="secondpaseslevelmenu" value="SERVICES" onClick={() => this.handleChange('SERVICES')}>
              <ListItemText className="secondpaseslevelmenu-text" inset primary={tt('SERVICIOS', this.props.locale)} />
            </ListItem>

            <ListItem button className="secondpaseslevelmenu" value="ARRIVE" onClick={() => this.handleChange('ARRIVE')}>
              <ListItemText className="secondpaseslevelmenu-text" inset primary={tt('CÓMO LLEGAR', this.props.locale)} />
            </ListItem>

          </List>
        </Collapse>

      </div>
    );

    const isBrowserTrue = (
      <Tabs
        value={value}
        onChange={this.handleChangeTab}
        centered
        variant="fullWidth"
        className={data.type === 'INFO' ? "tab-head bggrey" : "tab-head"}
        classes={{ indicator: classes.indicator }}
      >
        <Tab
          label={tt('INFORMACIÓN GENERAL', this.props.locale)}
          value="INFO"
          className={data.type === 'INFO'
            ? classes.active_tabStyle : classes.default_tabStyle}
          classes={{
            labelContainer: data.type === 'INFO'
              ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
        <Tab
          label={tt('SERVICIOS', this.props.locale)}
          value="SERVICES"
          className={data.type === 'SERVICES'
            ? classes.active_tabStyle + " SERVICES" : classes.default_tabStyle + " SERVICES"} //eslint-disable-line
          classes={{
            labelContainer: data.type === 'SERVICES'
              ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
        <Tab
          label={tt('CÓMO LLEGAR', this.props.locale)}
          value="ARRIVE"
          className={data.type === 'ARRIVE'
            ? classes.active_tabStyle + " ARRIVE" : classes.default_tabStyle + " ARRIVE"} //eslint-disable-line
          classes={{
            labelContainer: data.type === 'ARRIVE'
              ? classes.active_labelContainer : classes.labelContainer,
            wrapper: classes.Wrapper,
          }}
        />
      </Tabs>
    );

    return (
      <div className="tab-container-big">
        <MediaServerRender predicted="desktop" hydrated>
          <MediaMatcher
            mobile={isMobileTrue}
            tablet={isBrowserTrue}
            desktop={isBrowserTrue}
          />
        </MediaServerRender>


        {data.type === 'INFO' && (
          <TabContainer>
            <div className="info-tab-cont">
              <Thor data={data.thorData} />
              <Freya data={data.freyaData} />
              <H2 data={data.tyrData} />
              <Tyr data={data.tyrData} />
              <Ymir data={data.ymirData} />
              <Hellheim data={data.hellheimlData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'SERVICES' && (
          <TabContainer>
            <div className="services-tab-cont">
              <H2 data={data} />
              <div className="services-list-container">
                <div className="container-text-autocaravanas">
                  <H3 data={data.fenrirData1} />
                  <Fenrir data={data.fenrirData1} />
                  <div className="separator" />
                  <H3 data={data.fenrirData2} />
                  <Fenrir data={data.fenrirData2} />
                </div>
                <Freya data={data.freyaData} />
              </div>
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
        {data.type === 'ARRIVE' && (
          <TabContainer>
            <div className="hta-tab-cont">
              <H2 data={data} />
              {/* <div className="text-inner" dangerouslySetInnerHTML={{ __html: data.additionalInfo }} /> */}
              <div className="services-list-container">
                <div className="container-text-autocaravanas">
                  <H3 data={data.fenrirData} />
                  <Fenrir data={data.fenrirData} />
                </div>
                <Freya data={data.freyaData} />
              </div>
              <Ymir data={data.ymirData} />
            </div>
          </TabContainer>
        )}
      </div>
    );
  }
}

HotelsTab.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HotelsTab);
