import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate } from 'gatsby';
import LayoutHotel from '../src/components/LayoutHotels/Main';
import Layout from '../src/components/Layout/Layout';
import HotelTab from '../src/components/Tabs/CaravaningTabs/CaravaningTab';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import SEO from '../src/helpers/seo';
import tt from '../src/helpers/translation';
import './ficha_caravan.scss';

const findDestination = require('../src/helpers/findDestination');
const urls = require('../src/constants/urls');

class HotelCaravaning extends React.Component {
  state = {};

  handleDataChange = (type) => {
    const { hotel_name, tab_name } = this.props.pageContext;
    const lang = this.props.pageContext.locale;

    const validOptions = {
      hotelname: urls.templates_multilang.ficha_hoteles.options.hotelname[lang],
      section: urls.templates_multilang.ficha_hoteles.options.section[lang],
    };
    let section = '';

    const sectionBase = urls.templates_multilang.ficha_hoteles.options.section[lang];
    switch (type) {
      case 'INFO':
        section = sectionBase[0];
        break;
      case 'SERVICES':
        section = sectionBase[4];
        break;
      case 'ARRIVE':
        section = sectionBase[5];
        break;
    }
    const localizedHotelName = validOptions.hotelname[6];
    const localizedUrl = urls.templates_multilang.ficha_hoteles.urls.filter(
      (a) => a.lang === lang
    )[0];
    const dest = findDestination.findDestination(localizedUrl.urlGatsby, validOptions, [
      localizedHotelName,
      section,
    ]);

    navigate(dest);
  };

  prepareForYmir = () => {
    const dc = this.props.data.allAdditionalLinks.edges[0].node.cards;
    let scheduleType;
    switch (this.props.pageContext.locale) {
      case 'es':
        scheduleType = 'horarios-calendario';
        break;
      case 'fr':
        scheduleType = 'horaires-calendrier';
        break;
      case 'ca':
        scheduleType = 'horaris-calendari';
        break;
      case 'en':
        scheduleType = 'dates-times';
        break;
      case 'de':
        scheduleType = 'oeffnungszeiten-kalender';
        break;
      case 'it':
        scheduleType = 'orari-calendari';
        break;
      case 'ru':
        scheduleType =
          '%D1%80%D0%B0%D1%81%D0%BF%D0%B8%D1%81%D0%B0%D0%BD%D0%B8%D0%B5-%D0%B3%D1%80%D0%B0%D1%84%D0%B8%D0%BA';
        break;
      default:
        scheduleType = 'horarios-calendario';
    }
    const ret2 = [
      {
        title: dc._0.title,
        buttonCard: dc._0.buttonCard,
        link: `${this.props.pageContext.options[0]}/${scheduleType}`,
        icon: dc._0.icon,
      },
      {
        title: dc._1.title,
        buttonCard: dc._1.buttonCard,
        link: dc._1.link,
        icon: dc._1.icon,
      },
      {
        title: dc._2.title,
        buttonCard: dc._2.buttonCard,
        link: dc._2.link,
        icon: dc._2.icon,
      },
    ];
    return ret2;
  };

  prepareForThor = () => {
    const dn = this.props.data.allListBeneficiosInfoGeneral.edges[0].node;
    const ret = {
      title: dn.title,
      subtitle: dn.subtitle,
      cards: [
        {
          Icon: dn.cards._0.Icon,
          titleIcon: dn.cards._0.titleIcon,
          subtitleIcon: dn.cards._0.subtitleIcon,
        },
        {
          Icon: dn.cards._1.Icon,
          titleIcon: dn.cards._1.titleIcon,
          subtitleIcon: dn.cards._1.subtitleIcon,
        },
        {
          Icon: dn.cards._2.Icon,
          titleIcon: dn.cards._2.titleIcon,
          subtitleIcon: dn.cards._2.subtitleIcon,
        },
      ],
    };
    return ret;
  };

  render() {
    const { pageContext } = this.props;
    const { sendData } = this.state;

    let sendInfo;

    if (pageContext.tab_name === '') {
      sendInfo = {
        type: 'INFO',
        thorData: this.prepareForThor(),
        tyrData: {
          title: 'Parking Caravaning',
          cards: this.props.data.allRoomInfoGeneralSubBlock.edges.map((block) => ({
            MainImage: block.node.localImage.childImageSharp.fluid,
            Image: block.node.imageIcon,
            Title: block.node.title,
            TitleURL: block.node.button1.cta,
            Text: block.node.description,
            button1: {
              cta: block.node.button1.cta,
              size: 'alone',
              color: 'white',
              ctaText: block.node.button1.ctaText,
            },
          })),
        },
        freyaData: {
          title: 'HABITACIONES',
          cards: [
            {
              image: this.props.data.allOffersByPhoneBlock.edges[0].node.localImage.childImageSharp
                .fluid,
              title: this.props.data.allOffersByPhoneBlock.edges[0].node.title,
              description: this.props.data.allOffersByPhoneBlock.edges[0].node.description,
              imgsize: 'large',
              seeMore: '/entradas',
              twoButton: false,
              buttons: {
                cta: `tel:${tt('977 77 90 90', this.props.pageContext.locale)}`,
                size: 'alone',
                color: 'white',
                ctaText: tt('977 77 90 90', this.props.pageContext.locale),
              },
            },
          ],
        },
        ymirData: {
          cards: this.prepareForYmir(),
        },
        hellheimlData: {
          title: this.props.data.allFaqBlock.edges[0].node.title,
          menus: this.props.data.allFaqBlockSub.edges.map((item, index) => {
            return {
              title: item.node.question,
              text: item.node.answer,
            };
          }),
        },
      };
    }

    if (pageContext.tab_name === 'servicios') {
      sendInfo = {
        type: 'SERVICES',
        title: this.props.data.allHeaderBlockServicios.edges[0].node.title,
        description: this.props.data.allHeaderBlockServicios.edges[0].node.subTitle,
        ymirData: {
          cards: this.prepareForYmir(),
        },
        freyaData: {
          title: 'HABITACIONES',
          cards: [
            {
              image: this.props.data.allOffersByPhoneBlock.edges[0].node.localImage.childImageSharp
                .fluid,
              title: this.props.data.allOffersByPhoneBlock.edges[0].node.title,
              description: this.props.data.allOffersByPhoneBlock.edges[0].node.description,
              imgsize: 'large',
              seeMore: '/entradas',
              twoButton: false,
              buttons: {
                cta: `tel:${tt('977 77 90 90', this.props.pageContext.locale)}`,
                size: 'alone',
                color: 'white',
                ctaText: tt('977 77 90 90', this.props.pageContext.locale),
              },
            },
          ],
        },
        fenrirData1: {
          list: this.props.data.allListBeneficiosExclusivosSubBlock.edges[0].node.body_left,
          title: tt(
            'EL PAQUETE ESTACIONAMIENTO + SERVICIOS INCLUYE',
            this.props.pageContext.locale
          ),
          listTitle: 'Servicios',
        },
        fenrirData2: {
          list: this.props.data.allListBeneficiosExclusivosSubBlock.edges[1].node.body_left,
          title: tt('CONDICIONES', this.props.pageContext.locale),
          listTitle: tt('Servicios', this.props.pageContext.locale),
        },
      };
    }

    if (pageContext.tab_name === 'situacion') {
      sendInfo = {
        type: 'ARRIVE',
        title: this.props.data.allHeaderBlockSituacion.edges[0].node.title,
        description: this.props.data.allHeaderBlockSituacion.edges[0].node.subTitle,
        additionalInfo: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.description,
        fenrirData: {
          list: this.props.data.allListBeneficiosExclusivosSubBlock.edges[0].node.body_left,
          title: undefined,
          listTitle: 'Situacion',
        },
        freyaData: {
          title: tt('HABITACIONES', this.props.pageContext.locale),
          cards: [
            {
              image: this.props.data.allOffersByPhoneBlock.edges[0].node.localImage.childImageSharp
                .fluid,
              title: this.props.data.allOffersByPhoneBlock.edges[0].node.title,
              description: this.props.data.allOffersByPhoneBlock.edges[0].node.description,
              imgsize: 'large',
              seeMore: '/entradas',
              twoButton: false,
              buttons: {
                cta: `tel:${tt('977 77 90 90', this.props.pageContext.locale)}`,
                size: 'alone',
                color: 'white',
                ctaText: tt('977 77 90 90', this.props.pageContext.locale),
              },
            },
          ],
        },
        ymirData: {
          cards: this.prepareForYmir(),
        },
      };
    }
    const layoutData = this.props.data.allHeaderBlockInfoGeneral.edges[0].node;
    // layoutData.button1.ctaText = tt('+34 902 20 22 20', this.props.pageContext.locale);
    // layoutData.button1.cta = tt('tel:902202220', this.props.pageContext.locale);
    const button1 = null;
    // const button1 = {
    //   cta: tt('/hoteles/check-in-online', this.props.pageContext.locale),
    //   size: 'small',
    //   color: 'white',
    //   ctaText: tt('CHECKIN ONLINE', this.props.pageContext.locale),
    // };
    const button2 = {
      cta: `tel:${tt('977 77 90 90', this.props.pageContext.locale)}`,
      size: 'small',
      color: 'orange',
      ctaText: tt('977 77 90 90', this.props.pageContext.locale),
    };

    const heimdallData = {
      name: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.title,
      image: {
        url: this.props.data.allHeaderBlockImageInfoGeneral.edges[0].node.localImage.childImageSharp
          .fluid,
      },
      starsHotel: this.props.data.allHeaderBlockInfoGeneral.edges[0].node.stars,
      starsColor: 'white',
    };
    this.props.data.allHeaderBlockInfoGeneral.edges[0].node.button1.ctaText = this.props.data.allOffersByPhoneBlock.edges[0].node.phone.replace(
      'tel:',
      ''
    );
    return (
      <Layout
        layoutFromOld
        locale={this.props.pageContext.locale}
        pageName={this.props.pageContext.pageName}
        options={this.props.pageContext.options}
      >
        <SEO
          title={this.props.data.allHotelDetailsSeoData.edges[0].node._3.title}
          description={this.props.data.allHotelDetailsSeoData.edges[0].node._4.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <LayoutHotel
          // urlCTA={
          //   this.props.data.allOffersByPhoneBlock.edges[0] &&
          //   this.props.data.allOffersByPhoneBlock.edges[0].node.phone
          // }
          button2={button2}
          button1={button1}
          heimdallData={heimdallData}
          promos={this.props.data.allPromoInfoGeneralSubBlock.edges}
          icon={
            this.props.data.allHotelIconBlock.edges.length &&
            this.props.data.allHotelIconBlock.edges[0].node.Image
          }
          layoutImage={
            this.props.data.allHeaderBlockImageInfoGeneral.edges[0].node.localImage.childImageSharp
              .fluid
          }
          layoutData={this.props.data.allHeaderBlockInfoGeneral.edges[0].node}
        >
          <div className="caravaning-details-container">
            <BlueArrow />
            <NeoAesir
              locale={this.props.pageContext.locale}
              pageName={this.props.pageContext.pageName}
              options={this.props.pageContext.options}
              title={this.props.data.allFichaHotelesBreadCrumbBlock.edges[0].node.name}
            />
            <HotelTab
              data={sendInfo}
              onChangeData={this.handleDataChange}
              locale={this.props.pageContext.locale}
            />
          </div>
        </LayoutHotel>
      </Layout>
    );
  }
}
/* eslint-enable react/require-default-props */

export default HotelCaravaning;

export const pageQuery = graphql`
  query allFichaCaravanaQuery($hotel_name: String!, $tab_name: String!, $locale: String!) {
    allHotelDetailsSeoData(
      filter: { _1: { hotel: { eq: $hotel_name } }, _0: { tab: { eq: $tab_name } } }
    ) {
      edges {
        node {
          _0 {
            tab
          }
          _1 {
            hotel
          }
          _2 {
            tag
          }
          _3 {
            title
          }
          _4 {
            description
          }
        }
      }
    }
    allHeaderBlockInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: "" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          link
          colorRound
          description
          title
          subTitle
          stars
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }

    allHeaderBlockServicios: allHeaderBlockInfoGeneral(
      limit: 1
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          link
          colorRound
          description
          title
          subTitle
          stars
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }

    allHeaderBlockSituacion: allHeaderBlockInfoGeneral(
      limit: 1
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          tab
          link
          colorRound
          description
          title
          subTitle
          stars
          button1 {
            cta
            size
            color
            ctaText
          }
          button2 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allHeaderBlockImageInfoGeneral(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          hotel
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allOffersByPhoneBlock(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          title
          description
          phone
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 200, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allListCaravaningMosaic(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          title
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 380, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          color_css_class
          summary
          call_to_action_path
          call_to_action_copy
        }
      }
    }
    allPromoInfoGeneralSubBlock(
      filter: { hotel: { eq: "gold-river" }, tab: { eq: "" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
          description
          localImage {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          imgsize
          stars
          buttons {
            cta
            size
            color
            ctaText
          }
          description
        }
      }
    }
    allListBeneficiosInfoGeneral(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          hotel
          tab
          title
          subtitle
          cards {
            _0 {
              Icon
              titleIcon
              subtitleIcon
            }
            _1 {
              Icon
              titleIcon
              subtitleIcon
            }
            _2 {
              Icon
              titleIcon
              subtitleIcon
            }
          }
        }
      }
    }
    allAdditionalLinks(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          id
          cards {
            _0 {
              title
              buttonCard
              link
              icon
            }
            _1 {
              title
              buttonCard
              link
              icon
            }
            _2 {
              title
              buttonCard
              link
              icon
            }
          }
        }
      }
    }
    allFaqBlockSub(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          question
          answer
        }
      }
    }
    allFaqBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          id
          title
        }
      }
    }
    allListBeneficiosExclusivosSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tab
          title
          body_left
          body_right
        }
      }
    }
    allFichaHotelesBreadCrumbBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          destination_url
          name
        }
      }
    }
    allHotelIconBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          Image
          ctaURL
        }
      }
    }
    allRoomInfoGeneralSubBlock(
      filter: { hotel: { eq: $hotel_name }, tab: { eq: $tab_name }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 380, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imageIcon
          title
          description
          mainText
          secondaryText
          button1 {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
  }
`;
